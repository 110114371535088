import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

// Profile can include functionalLocations array that comes from cache.
// This data can be long and is irrelevant, so let's get rid of that.
// This will prevent API from returning error 413 while saving.
const prepareProfileData = (profile = {}) => {
  const newProfile = { ...profile };
  delete newProfile.functionalLocations;
  return newProfile;
};

export const myProfile = () =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/profiles`,
    noCache: true,
    useAuthorization: true,
  });

export const profileById = id =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/profiles/${id}`,
    noCache: true,
    useAuthorization: true,
  });

export const searchProfiles = (filter, noCache = false) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/profiles`,
    noCache,
    query: filter,
    useAuthorization: true,
  });

export const saveProfile = profile =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/profiles`,
    noCache: true,
    data: prepareProfileData(profile),
    useAuthorization: true,
  });

export const createProfile = profile =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/profiles`,
    noCache: true,
    data: profile,
    useAuthorization: true,
  });

export const deleteProfile = profileId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/profiles/${profileId}`,
    noCache: true,
    useAuthorization: true,
  });

export const profileByVerificationCode = verificationCode =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/profiles/by-verification-code`,
    noCache: true,
    data: { code: verificationCode },
    useAuthorization: false,
  });

export const verifyProfile = (verificationCode, password) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/profiles/verify`,
    noCache: true,
    data: { password, code: verificationCode },
    useAuthorization: false,
  });

export const resendProfileVerification = profileId =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/profiles/resend-verification`,
    data: { id: profileId },
    noCache: true,
    useAuthorization: true,
  });

export const acknowledgeTos = text =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/profiles/acknowledge-tos`,
    data: { text },
    useAuthorization: true,
  });

export const ping = () => {
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/me`,
    noCache: true,
    useAuthorization: true,
  });
};
