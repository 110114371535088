import uniq from 'lodash/uniq';
import Cookies from 'utils/Cookies/Cookies';

export const getWelcomeUrl = () => {
  const cookie = Cookies.getItem('language');
  if (!cookie) {
    return `${window.location.origin}`;
  }

  const languages = uniq(cookie.split(',').map(lang => lang.split(/[_-]/)[0]));
  const languageParts = languages.map(lang => {
    if (lang === 'nn' || lang === 'nb') {
      return 'no';
    }
    if (lang === 'sv') {
      return 'sv';
    }
    if (lang === 'fi') {
      return 'fi';
    }
    if (lang === 'da') {
      return 'da';
    }
    if (lang === 'de') {
      return 'de';
    }
    if (lang === 'ru') {
      return 'ru';
    }
    return 'en';
  });

  return `${window.location.origin}/?lang=${languageParts[0]}`;
};

export const getWelcomeUrlForLanguage = lang => {
  let languagePart = '';

  if (lang === 'nn' || lang === 'nb') {
    languagePart = 'no';
  }
  if (lang === 'sv') {
    languagePart = 'sv';
  }
  if (lang === 'fi') {
    languagePart = 'fi';
  }
  if (lang === 'da') {
    languagePart = 'da';
  }
  if (lang === 'de') {
    languagePart = 'de';
  }
  if (lang === 'ru') {
    languagePart = 'ru';
  }

  return `${window.location.origin}/?lang=${languagePart}`;
};

// Convert mistakenly used se to sv
export const fixLanguage = language => (language === 'se' ? 'sv' : language);
