import Button, { StyledButton } from 'components/Button/PrimaryButton/PrimaryButton';
import Loading from 'components/Loading/Loading';
import LanguageSelector from 'containers/Technician/LanguageSelector/LanguageSelector';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PageTemplate from './PageTemplate';

const Title = styled.h1`
  font-weight: bold;
  margin-bottom: var(--size-xs);
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.lg};
`;

const SubTitle = styled.h1`
  margin-bottom: var(--size-lg);
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.lg};
`;

const Links = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: var(--size-lg);
  a {
    font-size: 0.75em;
    margin: var(--size-xs);
  }
`;

const LoginHint = styled.p`
  margin: var(--size-lg);
  font-size: 0.75em;
  color: #888e97;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  ${StyledButton} {
    margin: var(--size-xs);
  }
`;

const WelcomeLogin = () => {
  const { t, selectedLanguage, setSelectedLanguage, translationsReady } = useUnauthenticatedTranslations();
  const languageSelector = (
    <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
  );
  if (!translationsReady) {
    return <Loading />;
  }
  return (
    <PageTemplate title={t('Welcome to SmartView')} languageSelector={languageSelector}>
      <Title>{t('Welcome to SmartView')}</Title>
      <SubTitle>{t('How is your building performing today?')}</SubTitle>
      <ButtonContainer>
        <Button large add reactLink to="/login">
          {t('Log in')}
        </Button>
      </ButtonContainer>
      <LoginHint>({t('You will be directed to Microsoft Login Service for login.')})</LoginHint>
      <Links>
        <Link to="/LoginHelp">{t('Having trouble logging in?')}</Link>
        <Link to="/TermsOfService">{t('Terms and Conditions')}</Link>
        <Link to="https://www.caverion.com/about-us/privacy-notice">{t('Privacy notice')}</Link>
      </Links>
    </PageTemplate>
  );
};

export default WelcomeLogin;
