import * as profile from './profile';
import * as localization from './localization';
import * as apiKeys from './apiKeys';
import * as apiQuotas from './apiQuotas';

export const PROFILE_PATH = '/profile';
export const SERVICE_API_PATH = '/api';

const services = {
  ...apiKeys,
  ...apiQuotas,
  ...localization,
  ...profile,
};

export default services;
